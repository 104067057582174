import React, { useState, useEffect } from "react"
import styled from "styled-components"
/* eslint-disable jsx-a11y/accessible-emoji */

const HeroDiv = styled.div`
    position: relative;
    overflow: hidden;
    background: #4157FE;
    height: 100vh;
    padding-top: 14vh;

    @media only screen and (min-width: 750px) {
        padding-top: 16vh;
        height: 100vh;
    }

    @media only screen and (min-width: 1024px) {
        padding-top: 23vh;
        min-height: 675px;
    }
`;

const WrapperDiv = styled.div`
    margin: 0 auto;
    max-width: 1180px;
    position: relative;
    z-index: 10;
    padding: 0 30px;

    @media only screen and (min-width: 750px) {
        padding: 0 20px;
    }
`;

const HeroHeadline = styled.h1`
    display: block;
    font-family: 'Graphik Bold', Helvetica, Arial, sans-serif;
    font-size: 48px;
    line-height: 48px;
    font-weight: 400;
    letter-spacing: -2px;
    color: #FFFFFF;
    margin: 33px 0;

    @media only screen and (min-width: 750px) {
        font-size: 68px;
        line-height: 76px;
        letter-spacing: -3px;
    }

    @media only screen and (min-width: 1024px) {
        font-size: 80px;
        line-height: 90px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: ${({ smallHero }) => smallHero ? '80px' : '96px'};
        line-height: ${({ smallHero }) => smallHero ? '90px' : '104px'};
    }
`;

const RecentLaunches = styled.span`
    display: block;
    font-family: 'Graphik Bold', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: #FFFFFF;
    margin-top: 50px;

    @media only screen and (min-width: 750px) {
        font-size: 32px;
        letter-spacing: -1px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: ${({ smallHero }) => smallHero ? '50px' : '84px'};
    }
`;

const EmojiDown = styled.span`
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    margin-left: 3px;

    @media only screen and (min-width: 750px) {
        font-size: 32px;
    }
`;

const HomeHero = () => {
    const [smallHero, setSmallHero] = useState(false);

    const smallScreen = () => setSmallHero(true);

    const largeScreen = () => setSmallHero(false);

    const heroSizing = () => {
        if (window.innerWidth >= 1024 && window.innerHeight <= 800) {
            smallScreen();
        } else {
            largeScreen();
        }
    }

    useEffect(() => {
        window.addEventListener('resize', heroSizing);

        return () => {
            window.removeEventListener('resize', heroSizing);
        }
    });

    return (
        <HeroDiv>
            <WrapperDiv>
                <HeroHeadline smallHero={smallHero}>We create beautiful and functional ecommerce experiences for amazing brands on Shopify.</HeroHeadline>
                <RecentLaunches smallHero={smallHero}>
                    <span>Recent launches</span>
                    <EmojiDown role="img" aria-label="Pointing Down Emoji">👇</EmojiDown>
                </RecentLaunches>
            </WrapperDiv>
        </HeroDiv>
    );
}

export default HomeHero