import React from "react"
import SEO from "../components/seo"
import HomeWidget from "../components/home/homeWidget"
import HomeHero from "../components/home/homeHero"
import metaImage from "../images/meta-image.png"

const indexTemplate = ({ data, ...props }) => {
    return (
        <>
            <SEO
                title="Pointer | Ecommerce Design and Development for Shopify"
                url="https://pointercreative.com"
                image={`https://pointercreative.com${metaImage}`}
            />
            <HomeHero/>
            <HomeWidget/>
        </>
    )
}

export default indexTemplate
