import React, { useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import posed, { PoseGroup } from "react-pose"
import Img from "gatsby-image"
/* eslint-disable jsx-a11y/accessible-emoji, no-unused-vars */

const MenuDiv = styled.div`
    position: relative;
    display: block;
    overflow: hidden;
`;

const WrapperDiv = styled.div`
    position: relative;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0;

    @media only screen and (min-width: 1024px) {
        padding: 0 20px;
    }
`;

const halfWidth = `
    width: 50%;
    float: left;
    display: inline-block;
    position: relative;
`;

const MenuItemsDiv = styled.div`
    padding: 50px 0 0 0;

    @media only screen and (min-width: 1024px) {
        ${halfWidth}
        padding: 50px 0 0 0;
        height: 835px;
    }

    @media only screen and (min-width: 1200px) {
        padding: 60px 0 0 0;
    }
`;

const MenuItem = styled.div`
    display: block;
    width: 100%;
    margin-bottom: 90px;
    cursor: pointer;

    @media only screen and (min-width: 750px) {
        display: inline-block;
        width: 50%;
    }

    @media only screen and (min-width: 1024px) {
        margin-bottom: 0;
        width: 100%;
        display: block;
    }
`;

const StyledLink = styled.a`
    transition: color 0.2s cubic-bezier(0.46, 0.01, 0.32, 1);
    font-family: 'Graphik Bold', Helvetica, Arial, sans-serif;
    font-size: 46px;
    line-height: 0;
    letter-spacing: -2px;
    text-decoration: none;
    color: #000000;
    margin: 0 40px 0 0;
    padding: 0 29px;
    white-space: nowrap;

    @media only screen and (min-width: 750px) {
        font-size: 50px;
        margin: 0 60px 0 0;
        padding: 0 20px;
    }

    @media only screen and (min-width: 1024px) {
        padding: 0;
        font-size: 64px;
        line-height: 80px;
        letter-spacing: -4px;
        margin: 0;
        color: #DADADA;
    }

    &:hover {
        color: #000000;
    }
`;

const MenuImageDiv = styled.div`
    overflow: hidden;
    height: 500px;
    position: absolute;
    right: 0;
    top: 0;

    @media only screen and (min-width: 1024px) {
        ${halfWidth}
        height: 835px;
    }
`;

const ImageDiv = styled(BackgroundImage)`
    position: absolute !important;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
`;

const MobileImage = styled(Img)`
    display: block;
    width: 100%;
    height: auto;
    margin-top: 20px;

    @media only screen and (min-width: 1024px) {
        display: none;
        margin-top: 0;
    }
`;

const FadeInImage = posed.div({
    entering: {
        opacity: 1,
        transition: {
            opacity: {
                ease: 'easeIn',
                duration: 250,
                delay: 100
            }
        }
    },
    exiting: {
        opacity: 0,
        transition: {
            opacity: {
                ease: 'easeOut',
                duration: 250,
                delay: 100
            }
        }
    }
});

const imageWidth = 375;

const calculateXTo = ({ from, element, imageWrap, divWidth, value: { raw } }) => {
    let to = 0;
    const { x } = element.getBoundingClientRect();
    const { width } = imageWrap.current.getBoundingClientRect();
    const max = (-(divWidth) + width);
    const slide = (Math.abs(Math.ceil(x / imageWidth))) + 1;
    const isLeftSwipe = from < raw.prev;
    const defaultTo = isLeftSwipe ? -(slide * imageWidth) : -(slide * imageWidth) + imageWidth;
    if (x > 0) {
        to = 0;
    } else if (x < max) {
        to = max;
    } else {
        to = defaultTo;
    }
    return to;
};

const MenuItemWrapper = styled.div`
    display: block;
    width: auto;
    margin: 40px 0 0;
`;

function HomeWidgetSites({ sites }) {

    const [menuState, setMenuState] = useState({
        currentSlug: null,
        imageWrap: React.createRef()
    });

    let flag = 0;

    const mouseDown = () => flag = 0;

    const mouseMove = () => flag = 1;

    const hoverOn = ({ slug }) => setMenuState({
        ...menuState,
        currentSlug: slug
    });

    const hoverOff = () => setMenuState({
        ...menuState,
        currentSlug: null
    });

    const navigation = ({ slug }) => flag === 0 ? navigate(`/site/${slug}`) : null;

    const { currentSlug, imageWrap } = menuState;

    return (
        <MenuDiv id="work">
            <WrapperDiv>
                <MenuItemsDiv ref={imageWrap}>
                    <MenuItemWrapper
                        divWidth={sites.length * imageWidth}
                        imageWrap={imageWrap}
                        onMouseDown={mouseDown}
                        onMouseMove={mouseMove}
                    >
                        {sites.map(({ id, title, slug, thumbnail_image }, i) => (
                            <MenuItem
                                key={id}
                                onMouseEnter={() => hoverOn({ thumbnail_image, slug })}
                                onMouseLeave={hoverOff}
                                currentIndex={i}
                            >
                                <StyledLink
                                    onClick={() => navigation({ slug })}
                                >
                                    {title}
                                </StyledLink>
                                <div
                                    onClick={() => navigation({ slug })}
                                >
                                    <MobileImage
                                        fluid={thumbnail_image.asset.fluid}
                                        imageWidth={imageWidth}
                                    />
                                </div>
                            </MenuItem>
                        ))}
                    </MenuItemWrapper>
                </MenuItemsDiv>
            </WrapperDiv>
            <MenuImageDiv>
                <PoseGroup>
                    {sites.map(({ slug, thumbnail_image }) => (
                        <FadeInImage
                            key={slug}
                            pose={slug === currentSlug ? 'entering' : 'exiting'}
                        >
                            <ImageDiv
                                Tag="div"
                                fluid={thumbnail_image.asset.fluid}
                            ></ImageDiv>
                        </FadeInImage>
                    ))}
                </PoseGroup>
            </MenuImageDiv>
        </MenuDiv>
    )
}

export default HomeWidgetSites