import React from "react"
import { StaticQuery, graphql } from "gatsby"
import HomeWidgetSites from "../home/homeWidgetSites"

export default () => (
    <StaticQuery
        query={graphql`
            query {
                sanityPageWidgets(title: { eq: "home" }) {
                    id
                    title
                    widget_sites {
                        id
                        title
                        slug
                        thumbnail_image {
                            asset {
                                fluid(maxWidth: 1000) {
                                    ...GatsbySanityImageFluid_noBase64
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={({ sanityPageWidgets: { widget_sites } }) => (<><HomeWidgetSites sites={widget_sites}/></>)}
    />
)